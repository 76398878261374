import React from "react";
import PropTypes from "prop-types";
import { withNamespaces } from "react-i18next";

import estilos from "./CompMenuExpandible.css";

import List from "@material-ui/core/List";
import { ListItem, ListItemText, Collapse, Divider } from "@material-ui/core";
import EleIcono from "../../elementos/iconos/EleIcono";

/**
 * Componente menú expandible.
 *
 * @version 0.1
 * @author [Mario Cantelar](https://www.sandav.es) | [mario.cantelar@sandav.es](mailto:mario.cantelar@sandav.es)
 */
class CompMenuExpandible extends React.Component {
    static propTypes = {
        /**
         * Item seleccionado.
         */
        seleccionado: PropTypes.string.isRequired,
        /**
         * Función que se lanza cuando se pulsa sobre una opción del menú.
         */
        funcionOnSelect: PropTypes.func.isRequired,
        /**
         * Array de objetos con las opciones a visualizar.
         */
        opcionesMenu: PropTypes.array.isRequired
    };

    constructor(props) {
        super(props);
        this.elementosAbiertos = null;
        this.menuSeleccionado = null;

        this.entraEnLogin = false;
    }

    funcionOnAuxClickElemento = (ruta, evento) => {
        if (evento.button === 1) {
            this.props.funcionOnSelectAux(ruta);
        }
    };

    funcionOnClickElemento = (ruta, claveElemento) => {
        if (ruta === this.props.seleccionado) {
            this.props.funcionOnSelect(ruta);
            return;
        }

        this.props.funcionOnSelect(ruta);

        if (claveElemento) {
            let eleAbiertos = this.elementosAbiertos;

            if (!eleAbiertos) {
                eleAbiertos = [];
            } else {
                eleAbiertos = eleAbiertos.slice(0);
            }

            if (eleAbiertos.includes(claveElemento)) {
                eleAbiertos.splice(eleAbiertos.indexOf(claveElemento), 1);
            } else {
                eleAbiertos = [];
                eleAbiertos.push(claveElemento);
            }

            this.elementosAbiertos = eleAbiertos;
            this.forceUpdate();
        }
    };

    generaOpcionesNav = (opcionesMenu, clavePadre = "", arrayPadres = []) => {
        if (!opcionesMenu || !this.props.t) {
            return null;
        }

        let nuevaOpcion = opcionesMenu.map((opcion, i) => {
            let claveUnica = clavePadre + "." + i;

            let padres = Object.assign([], arrayPadres);
            padres.push(claveUnica);

            let estaSeleccionado = this.props.seleccionado === opcion.ruta;
            if (estaSeleccionado && !this.elementosAbiertos) {
                this.elementosAbiertos = arrayPadres;
            }

            if (estaSeleccionado) {
                this.menuSeleccionado = opcion.titulo;
            }

            const estaAbierto = this.elementosAbiertos && this.elementosAbiertos.includes(claveUnica);

            let estiloLista = estilos.elementoListaInline;
            let estiloSeparador = estilos.separadorInline;
            if (opcion.opciones || clavePadre === "") {
                estiloLista = "";
                estiloSeparador = "";
            }

            let iconoExpansion = opcion.opciones ? <EleIcono color="action" icono={estaAbierto ? "expand_less" : "expand_more"} /> : null;

            let opcionesGeneradas = opcion.opciones ? (
                <React.Fragment>
                    <Collapse in={estaAbierto} timeout="auto" unmountOnExit>
                        <List component="div" dense disablePadding>
                            {this.generaOpcionesNav(opcion.opciones, claveUnica, padres)}
                        </List>
                    </Collapse>
                </React.Fragment>
            ) : null;

            return (
                <React.Fragment key={claveUnica}>
                    <ListItem
                        dense={!opcion.opciones && clavePadre === ""}
                        className={estiloLista}
                        selected={estaSeleccionado}
                        onClick={this.funcionOnClickElemento.bind(this, opcion.ruta, opcion.opciones ? claveUnica : null)}
                        // onAuxClick={this.funcionOnAuxClickElemento.bind(this, opcion.ruta)}
                        onMouseDown={this.funcionOnAuxClickElemento.bind(this, opcion.ruta)}
                        button
                    >
                        <span className={clavePadre === "" ? estilos.elementoLista : ""}>
                            <ListItemText className={estilos.contenedorItem} primary={this.props.t(opcion.titulo)} />
                            {iconoExpansion}
                        </span>
                    </ListItem>
                    <Divider light className={estiloSeparador} />
                    {opcionesGeneradas}
                </React.Fragment>
            );
        });

        return nuevaOpcion;
    };

    shouldComponentUpdate(nextProps) {
        if (nextProps.opcionesMenu !== this.props.opcionesMenu || nextProps.seleccionado !== this.props.seleccionado || nextProps.lng !== this.props.lng) {
            return true;
        }

        return false;
    }

    componentDidUpdate() {
        let apertura = this.aperturaMenuPredeterminado();
        if (apertura) {
            this.forceUpdate();
        }
    }

    componentDidMount() {
        this.aperturaMenuPredeterminado();
        this.forceUpdate();
    }

    aperturaMenuPredeterminado = () => {
        if (this.entraEnLogin === false && this.props.seleccionado === "/login") {
            this.entraEnLogin = true;
            this.elementosAbiertos = [];
            return true;
        } else if (this.props.seleccionado !== "/login" && this.entraEnLogin === true) {
            this.entraEnLogin = false;
        }

        if (this.props.opcionesMenu && this.props.opcionesMenu.length > 0 && (!this.elementosAbiertos || this.elementosAbiertos.length === 0)) {
            // No hay nada abierto

            let menusExpandibles = 0;
            let menuExpandible = null;

            this.props.opcionesMenu.forEach((opcionMenu, indice) => {
                if (opcionMenu.opciones) {
                    menusExpandibles++;
                    if (menusExpandibles === 1) {
                        menuExpandible = "." + indice;
                    }
                }
            });

            if (menusExpandibles === 1) {
                this.funcionOnClickElemento(undefined, menuExpandible);
                return true;
            }
        }

        return false;
    };

    render() {
        this.menuSeleccionado = null;
        const opcionesNav = this.generaOpcionesNav(this.props.opcionesMenu);

        if (!this.menuSeleccionado) {
            this.menuSeleccionado = this.props.seleccionado.replace("/", "");
        }

        this.menuSeleccionado = this.props.t(this.menuSeleccionado);
        if (this.menuSeleccionado) {
            document.title = this.props.nombreAplicacion + " - " + this.props.t(this.menuSeleccionado);
        } else {
            document.title = this.props.nombreAplicacion;
        }

        return (
            <List component="nav" className={estilos.lista}>
                {opcionesNav}
            </List>
        );
    }
}

export default withNamespaces()(CompMenuExpandible);
