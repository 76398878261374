import permisos from "../../sic/comunes/autorizacion/Permisos";

function construyeMenu(permisos_recibidos) {
    let menu = [];

    let ppal_opciones = [];
    let general_opciones = [];
    let gestion_opciones = [];
    let informes_opciones = [];

    if (permisos_recibidos && permisos_recibidos.length > 0) {
        // let haypermiso = false;

        const esAdmin = permisos_recibidos.find(permiso => permiso === permisos.esAdmin);
        const esComercial = permisos_recibidos.find(permiso => permiso === permisos.esComercial);

        if (esAdmin) {
            // Administrador

            general_opciones.push({
                titulo: permisos.auth.user.titulo,
                ruta: permisos.auth.user.ruta
            });

            general_opciones.push({
                titulo: permisos.mantenimientos.marcas.titulo,
                ruta: permisos.mantenimientos.marcas.ruta
            });

            general_opciones.push({
                titulo: permisos.mantenimientos.empresas.titulo,
                ruta: permisos.mantenimientos.empresas.ruta
            });

            general_opciones.push({
                titulo: permisos.mantenimientos.exhibicion.titulo,
                ruta: permisos.mantenimientos.exhibicion.ruta
            });

            general_opciones.push({
                titulo: permisos.mantenimientos.tiendas.titulo,
                ruta: permisos.mantenimientos.tiendas.ruta
            });

            general_opciones.push({
                titulo: permisos.mantenimientos.regiones.titulo,
                ruta: permisos.mantenimientos.regiones.ruta
            });

            general_opciones.push({
                titulo: permisos.mantenimientos.ejercicio.titulo,
                ruta: permisos.mantenimientos.ejercicio.ruta
            });

            general_opciones.push({
                titulo: permisos.mantenimientos.productos.titulo,
                ruta: permisos.mantenimientos.productos.ruta
            });

            general_opciones.push({
                titulo: permisos.mantenimientos.productosEmpresa.titulo,
                ruta: permisos.mantenimientos.productosEmpresa.ruta
            });

            general_opciones.push({
                titulo: permisos.mantenimientos.seccion.titulo,
                ruta: permisos.mantenimientos.seccion.ruta
            });

            general_opciones.push({
                titulo: permisos.mantenimientos.zonasComerciales.titulo,
                ruta: permisos.mantenimientos.zonasComerciales.ruta
            });

            general_opciones.push({
                titulo: "file_upload",
                ruta: "/file_upload"
            });

            general_opciones.push({
                titulo: "transfer_stores",
                ruta: "/transfer_stores"
            });

            menu.push({
                titulo: "home",
                ruta: "/"
            });

            gestion_opciones.push({
                titulo: permisos.gestion.puntosInterrupcion.titulo,
                ruta: permisos.gestion.puntosInterrupcion.ruta
            });
        } else if (esComercial) {
            // Comercial
            gestion_opciones.push({
                titulo: permisos.gestion.puntosInterrupcion.tituloComercial,
                ruta: permisos.gestion.puntosInterrupcion.rutaComercial
            });
        }

        // Visibles para todos
        gestion_opciones.push({
            titulo: permisos.mantenimientos.planificador.titulo,
            ruta: permisos.mantenimientos.planificador.ruta
        });

        gestion_opciones.push({
            titulo: "product_price_shop",
            ruta: "/product_price_shop"
        });

        informes_opciones.push({
            titulo: "evolution",
            ruta: "/rep_evolution"
        });

        informes_opciones.push({
            titulo: "shop_evolution",
            ruta: "/rep_shop_evolution"
        });

        informes_opciones.push({
            titulo: "sellout_total",
            ruta: "/rep_sellout_total"
        });

        informes_opciones.push({
            titulo: "sellout_shop",
            ruta: "/rep_sellout_shop"
        });

        informes_opciones.push({
            titulo: "sellout_by_shop",
            ruta: "/rep_sellout_by_shop"
        });

        informes_opciones.push({
            titulo: "shop_comparator",
            ruta: "/rep_shop_comparator"
        });

        informes_opciones.push({
            titulo: "control_panel",
            ruta: "/control_panel"
        });

        informes_opciones.push({
            titulo: "sales_opportunities",
            ruta: "/rep_sales_opportunities"
        });

        gestion_opciones.push({
            titulo: "suggestion_order",
            ruta: "/rep_suggestion_order"
        });

        informes_opciones.push({
            titulo: "buyprice",
            ruta: "/rep_buyprice"
        });

        informes_opciones.push({
            titulo: "interruption_points",
            ruta: "/rep_interruption_points"
        });

        informes_opciones.push({
            titulo: "interruption_planner",
            ruta: "/rep_interruption_planner"
        });

        // informes_opciones.push({
        //     titulo: "amazon",
        //     ruta: "/rep_amazon"
        // });
    } else {
        menu.push({
            titulo: "login",
            ruta: "/login"
        });
    }

    if (general_opciones.length > 0) {
        // general_opciones.sort((a, b) => a.titulo.localeCompare(b.titulo));

        menu.push({
            titulo: permisos.mantenimientos.titulo,
            opciones: general_opciones
        });
    }

    if (gestion_opciones.length > 0) {
        // gestion_opciones.sort((a, b) => a.titulo.localeCompare(b.titulo));

        menu.push({
            titulo: permisos.gestion.titulo,
            opciones: gestion_opciones
        });
    }

    if (informes_opciones.length > 0) {
        // informes_opciones.sort((a, b) => a.titulo.localeCompare(b.titulo));

        menu.push({
            titulo: "reports",
            opciones: informes_opciones
        });
    }

    if (ppal_opciones.length > 0) {
        menu = menu.concat(ppal_opciones);
    }

    return menu;
}

export default construyeMenu;
