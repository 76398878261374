import React from 'react';
import PropTypes from 'prop-types';
import estilos from './EleIcono.css';
import Icon from '@material-ui/core/Icon';

/**
 * Elemento que permite el mostrar un icono.
 *
 * @version 0.1
 * @author [Sara García](http://enlace.a.la.web.del.programador.com) | [sara.garcia@sandav.es](mailto:sara.garcia@sandav.es)
 */
const EleIcono = ({ icono, color, claseCss, fuente }) => (
    <Icon color={color} className={claseCss + ' ' + estilos.eleicono} fontSize={fuente}>
        {icono}
    </Icon>
);

EleIcono.propTypes = {
    /**
     * Nombre correspondiente al icono a mostrar
     */
    icono: PropTypes.any,

    /**
     * Color del icono
     */
    color: PropTypes.oneOf(['inherit', 'primary', 'secondary', 'action', 'error', 'disabled']),

    /**
     * Estilos que aplican en el elemento
     */
    claseCss: PropTypes.string,

    /**
     * Tamaño de la fuente
     */
    fuente: PropTypes.oneOf(['inherit', 'default', 'small', 'large'])
};

EleIcono.defaultProps = {
    color: undefined,
    icono: '',
    claseCss: ''
};

export default EleIcono;
