import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { loadReCaptcha } from "react-recaptcha-v3";

import "../../componentes/grid.css";
import "animate.css";
import "./App.css";

import { MuiThemeProvider, CssBaseline } from "@material-ui/core";
import "react-google-material-icons";
import i18n from "../../componentes/i18n";
import { I18nextProvider } from "react-i18next";
import CompMenuNavegacion from "../../componentes/menuNavegacion/CompMenuNavegacion";
import { recuperaPermisos, peticionServidor } from "./api/Api";
import { ProveedorPermisos } from "../../componentes/permisos/ProveedorPermisos";
import { CircularProgress } from "@material-ui/core";
import { dameUrlDominio } from "./api/Api";
import { opcionesIdiomas, opcionesTema, puntoFinalIdiomas, puntoFinalTraducciones } from "./configuracion.json";
import { peticionLogoutAuth } from "./api/ApiAuth";
import construyeMenu from "./ServicioConstruccionMenu";
import { ProveedorPeticion } from "../../componentes/ProveedorPeticion";

import packageJson from "../../../package.json";
import { crearTema } from "../../componentes/Utilidades";
import CacheBuster from "../../componentes/cacheBuster";

global.appVersion = packageJson.version;

const VistaLogin = lazy(() => import("./autenticacion/vistas/VistaLogin"));
const VistaUsuario = lazy(() => import("../usuarios/vistas/VistaUsuario"));
const VistaMarcas = lazy(() => import("../marcas/vistas/VistaMarcas"));
const VistaEmpresa = lazy(() => import("../empresas/vistas/VistaEmpresa"));
const VistaExhibicion = lazy(() => import("../exhibicion/vistas/VistaExhibicion"));
const VistaSeccionTienda = lazy(() => import("../seccionesTienda/vistas/VistaSeccionTienda"));
const VistaTienda = lazy(() => import("../tiendas/vistas/VistaTienda"));
const VistaRegion = lazy(() => import("../regiones/vistas/VistaRegion"));
const VistaEjercicio = lazy(() => import("../ejercicio/vistas/VistaEjercicio"));
const VistaProducto = lazy(() => import("../productos/vistas/VistaProducto"));
const VistaProductoEmpresa = lazy(() => import("../productosEmpresa/vistas/VistaProductoEmpresa"));
const VistaPlanificador = lazy(() => import("../planificador/vistas/VistaPlanificador"));
const VistaInicio = lazy(() => import("../inicio/vistas/VistaInicio"));
const VistaZonaComercial = lazy(() => import("../zonaComercial/vistas/VistaZonaComercial"));
const VistaPuntosInterrupcion = lazy(() => import("../puntosInterrupcion/vistas/VistaPuntosInterrupcion"));
const VistaCargaFichero = lazy(() => import("../cargaFichero/vistas/VistaCargaFichero"));
const VistaTraspasoTiendas = lazy(() => import("../traspasoTiendas/vistas/VistaTraspasoTiendas"));
const PrecioProductoTienda = lazy(() => import("../precioProductoTienda/PrecioProductoTienda"));
const VistaCambiaPassword = lazy(() => import("../../componentes/formularios/cambiaPassword/vistas/VistaCambiaPassword"));

const InformeEvolucion = lazy(() => import("../informes/evolucion/InformeEvolucion"));
const InformeEvolucionTienda = lazy(() => import("../informes/evolucionTienda/InformeEvolucionTienda"));
const InformePuntosInterrupcion = lazy(() => import("../informes/puntosInterrupcion/InformePuntosInterrupcion"));
const InformeSelloutTienda = lazy(() => import("../informes/selloutTienda/InformeSelloutTienda"));
const InformeSelloutPorTienda = lazy(() => import("../informes/selloutPorTienda/InformeSelloutPorTienda"));
const InformeSelloutTotal = lazy(() => import("../informes/selloutTotal/InformeSelloutTotal"));
const InformeComparadorTiendas = lazy(() => import("../informes/comparadorTiendas/InformeComparadorTiendas"));
const InformeAmazon = lazy(() => import("../informes/amazon/InformeAmazon"));
const InformeCuadroMando = lazy(() => import("../informes/cuadroMando/InformeCuadroMando"));
const InformePropuestaComercial = lazy(() => import("../informes/propuestaComercial/InformePropuestaComercial"));
const InformePropuestaSimple = lazy(() => import("../informes/propuestaSimple/InformePropuestaSimple"));
const InformeOportunidadesVenta = lazy(() => import("../informes/oportunidadesVenta/InformeOportunidadesVenta"));
const InformeInterrupcionesVisita = lazy(() => import("../informes/interrupcionesVisita/InformeInterrupcionesVisita"));

class App extends React.Component {
    constructor(props) {
        super(props);

        this.datosPeticiones = {
            puntoFinalTraducciones: puntoFinalTraducciones,
            puntoFinalIdiomas: puntoFinalIdiomas,
            peticionServidor: peticionServidor
        };

        this.path_pi = ["/interruption_points", "/interruption_point"];
        this.path_propuesta = ["/rep_buyprice", "/rep_buyprice/:empresa-:tienda"];

        this.state = {
            permisos: undefined,
            tema: crearTema(opcionesTema),
            datosPerfil: {
                fotoUsuario: null,
                usuario: null,
                dameFoto: this.dameFoto
            }
        };

        this.estiloFallback = { textAlign: "center", paddingTop: "10%" };
    }

    establecePermisos = async () => {
        this.setState({
            permisos: await recuperaPermisos()
        });
    };

    async componentDidMount() {
        this.establecePermisos();
        loadReCaptcha("6LePH5IUAAAAAGezUsyOHa_1ukkRjw2Jwv3sBH7w", this.funcionCaptchaCargado);
    }

    recibePermisos = nuevos_permisos => {
        this.setState({
            permisos: nuevos_permisos
        });
    };

    cambiaLuzTema = (nuevaLuz = this.state.tema.palette.type) => {
        this.setState({
            tema: crearTema(opcionesTema, null, nuevaLuz)
        });
    };

    cambiaColorTema = nuevoColor => {
        this.setState({
            tema: crearTema(opcionesTema, nuevoColor, null)
        });
    };

    dameFoto = async (eliminada = false) => {};

    render() {
        return (
            <CacheBuster>
                {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                        refreshCacheAndReload();
                    }

                    if (!this.state.permisos) {
                        return null;
                    }

                    return (
                        <MuiThemeProvider theme={this.state.tema}>
                            <CssBaseline />
                            <I18nextProvider i18n={i18n}>
                                <ProveedorPermisos.Provider value={this.state.permisos}>
                                    <ProveedorPeticion.Provider value={this.datosPeticiones}>
                                        <Router>
                                            <CompMenuNavegacion
                                                cambiaLuzTema={this.cambiaLuzTema}
                                                cambiaColorTema={this.cambiaColorTema}
                                                permisos={this.state.permisos}
                                                actualizarPerfil={this.state.datosPerfil}
                                                nombreAplicacion="As you sell"
                                                menuCambiaPassVisible={true}
                                                opcionesTema={opcionesTema}
                                                opcionesIdiomas={opcionesIdiomas}
                                                dameUrlDominio={dameUrlDominio}
                                                peticionLogoutAuth={peticionLogoutAuth}
                                                construyeMenu={construyeMenu}
                                                tieneImagenFondo={false}
                                            >
                                                <Suspense
                                                    fallback={
                                                        <div style={this.estiloFallback}>
                                                            <CircularProgress size={50} />
                                                        </div>
                                                    }
                                                >
                                                    <Route exact path="/" render={props => <VistaInicio {...props} />} />
                                                    <Route
                                                        exact
                                                        path="/login"
                                                        render={props => <VistaLogin funcionActualizaPermisos={this.recibePermisos} {...props} />}
                                                    />
                                                    <Route exact path="/users" render={props => <VistaUsuario {...props} />} />
                                                    <Route exact path="/brands" render={props => <VistaMarcas {...props} />} />
                                                    <Route exact path="/companies" render={props => <VistaEmpresa {...props} />} />
                                                    <Route exact path="/exhibition" render={props => <VistaExhibicion {...props} />} />
                                                    <Route exact path="/products" render={props => <VistaProducto {...props} />} />
                                                    <Route exact path="/company_products" render={props => <VistaProductoEmpresa {...props} />} />
                                                    <Route exact path="/section" render={props => <VistaSeccionTienda {...props} />} />
                                                    <Route exact path="/shops" render={props => <VistaTienda {...props} />} />
                                                    <Route exact path="/fiscal_year" render={props => <VistaEjercicio {...props} />} />
                                                    <Route exact path="/regions" render={props => <VistaRegion {...props} />} />
                                                    <Route exact path="/planner" render={props => <VistaPlanificador {...props} />} />
                                                    <Route exact path="/shoping_areas" render={props => <VistaZonaComercial {...props} />} />
                                                    <Route exact path={this.path_pi} render={props => <VistaPuntosInterrupcion {...props} />} />
                                                    <Route exact path="/file_upload" render={props => <VistaCargaFichero {...props} />} />
                                                    <Route exact path="/transfer_stores" render={props => <VistaTraspasoTiendas {...props} />} />
                                                    <Route exact path="/product_price_shop" render={props => <PrecioProductoTienda {...props} />} />
                                                    <Route
                                                        exact
                                                        path="/changePassword"
                                                        render={props => <VistaCambiaPassword peticionServidor={peticionServidor} {...props} />}
                                                    />

                                                    <Route exact path="/rep_evolution" render={props => <InformeEvolucion {...props} />} />
                                                    <Route exact path="/rep_shop_evolution" render={props => <InformeEvolucionTienda {...props} />} />
                                                    <Route exact path="/rep_interruption_points" render={props => <InformePuntosInterrupcion {...props} />} />
                                                    <Route exact path="/rep_sellout_shop" render={props => <InformeSelloutTienda {...props} />} />
                                                    <Route exact path="/rep_sellout_by_shop" render={props => <InformeSelloutPorTienda {...props} />} />
                                                    <Route exact path="/rep_sellout_total" render={props => <InformeSelloutTotal {...props} />} />
                                                    <Route exact path="/rep_shop_comparator" render={props => <InformeComparadorTiendas {...props} />} />
                                                    <Route exact path="/rep_amazon" render={props => <InformeAmazon {...props} />} />
                                                    <Route exact path="/rep_suggestion_order" render={props => <InformePropuestaSimple {...props} />} />
                                                    <Route exact path={this.path_propuesta} render={props => <InformePropuestaComercial {...props} />} />
                                                    <Route exact path="/control_panel" render={props => <InformeCuadroMando {...props} />} />
                                                    <Route exact path="/rep_sales_opportunities" render={props => <InformeOportunidadesVenta {...props} />} />
                                                    <Route
                                                        exact
                                                        path="/rep_interruption_planner"
                                                        render={props => <InformeInterrupcionesVisita {...props} />}
                                                    />
                                                </Suspense>
                                            </CompMenuNavegacion>
                                        </Router>
                                    </ProveedorPeticion.Provider>
                                </ProveedorPermisos.Provider>
                            </I18nextProvider>
                        </MuiThemeProvider>
                    );
                }}
            </CacheBuster>
        );
    }
}

export default App;
