import React from 'react';
import estilos from './CompPanelLateral.css';
import CompMenuExpandible from '../menus/CompMenuExpandible';
import { Drawer, withWidth } from '@material-ui/core';
import ElePanel from '../../elementos/paneles/ElePanel';

/**
 * Componente que visualiza panel con un menú de opciones.
 *
 * @version 0.1
 * @author [Mario Cantelar](https://www.sandav.es) | [mario.cantelar@sandav.es](mailto:mario.cantelar@sandav.es)
 */
const CompPanelLateral = ({ ...props }) => {
    const menuExpandible = <CompMenuExpandible {...props} />;
    let esMovilTablet = props.width === "xs" || props.width === "sm";

    return (
        <React.Fragment>
            <Drawer
                className={estilos.panelLateralMovil}
                open={esMovilTablet === true && props.menuLateralVisible}
                onClose={props.funcionCerrar}
                variant="temporary"
            >
                {menuExpandible}
            </Drawer>

            <div className={estilos.contenedorPanelPC + " " + (esMovilTablet === false && props.menuLateralVisible ? "" : estilos.panelPCCerrado)}>
                <ElePanel claseCss={estilos.panelPC} cuadrado={true}>
                    {menuExpandible}
                </ElePanel>
            </div>
        </React.Fragment>
    );
};

export default withWidth()(CompPanelLateral);
