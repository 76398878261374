import { createTheme } from "@material-ui/core";

const esDesarrollo = Boolean(
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/) ||
        // localhost or 192.168.* is local/lan
        window.location.hostname.match(/(192.168|localhost|[::1]).*/)
);

const clonar = elemento => {
    if (Array.isArray(elemento)) {
        return elemento.slice();
    } else if (typeof elemento === "object") {
        return Object.assign({}, elemento);
    }
};

const estaVacio = elemento => {
    if (elemento === null || elemento === undefined || elemento === "") {
        return true;
    }

    if (elemento.constructor === Array && elemento.length === 0) {
        return true;
    }

    return elemento.constructor === Object && Object.keys(elemento).length === 0;
};

const fechaUTC = (fecha = new Date()) => {
    if (typeof fecha === "string") {
        let fechaParseada = fecha.replaceAll("-", "/");
        fecha = new Date(fechaParseada);
    }

    var now_utc = Date.UTC(fecha.getFullYear(), fecha.getMonth(), fecha.getDate(), fecha.getHours(), fecha.getMinutes(), fecha.getSeconds());

    return new Date(now_utc);
};

const comprobarAdmiteInputMonth = () => {
    let esFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") >= 0;
    if (esFirefox) {
        return false;
    }

    let esSafari = navigator.userAgent.toLowerCase().indexOf("safari") >= 0 && navigator.userAgent.toLowerCase().indexOf("chrome") < 0;
    if (esSafari) {
        return false;
    }

    return true;
};

const fechaSinDia = (fecha = new Date()) => {
    if (typeof fecha === "string") {
        let fechaParseada = fecha.replace("-", "/");
        fecha = new Date(fechaParseada);
    }

    var mes = fecha.getUTCMonth() + 1;
    if (mes < 10) {
        mes = "0" + mes;
    }

    var ejercicio = fecha.getUTCFullYear();
    var now_utc_string = mes + "/" + ejercicio;

    return now_utc_string;
};

const fechaSinDiaUTC = (fecha = new Date()) => {
    if (typeof fecha === "string") {
        let fechaParseada = fecha.replace("-", "/");
        fecha = new Date(fechaParseada);
    }

    var mes = fecha.getUTCMonth() + 1;
    if (mes < 10) {
        mes = "0" + mes;
    }

    var ejercicio = fecha.getUTCFullYear();
    var now_utc_string = ejercicio + "-" + mes;

    return now_utc_string;
};

const fechaUTCString = (fecha = new Date()) => {
    if (typeof fecha === "string") {
        let fechaParseada = fecha.replace("-", "/");
        fecha = new Date(fechaParseada);
    }

    var dia = fecha.getUTCDate();
    if (dia < 10) {
        dia = "0" + dia;
    }

    var mes = fecha.getUTCMonth() + 1;
    if (mes < 10) {
        mes = "0" + mes;
    }

    var ejercicio = fecha.getUTCFullYear();
    var now_utc_string = ejercicio + "-" + mes + "-" + dia;

    return now_utc_string;
};

const recuperaSubCadena = (cadena, separador, indicadorSubcadena) => {
    let arraySubCadenas = [];
    arraySubCadenas = cadena.split(separador);
    return arraySubCadenas[indicadorSubcadena];
};

const recogerIdioma = idioma => {
    return recuperaSubCadena(idioma, "-", 0);
};

const formatearNumero = valor => {
    valor = parseFloat(valor)

    if (localStorage.getItem('i18nextLng') === "en-EN" || localStorage.getItem('i18nextLng') === "en-US") {
        return new Intl.NumberFormat('en-US', { currency: 'USD' }).format(valor)
    } else {
        return new Intl.NumberFormat('de-DE', { currency: 'EUR' }).format(valor)
    }
}

const crearTabla = (cabeceras, filas, traductor) => {
    let tabla = document.createElement("table");
    let camposVisibles = [];

    let cabecera = document.createElement("tr");
    for (let i in cabeceras) {
        let th = document.createElement("th");
        th.style.width = "700px";
        camposVisibles.push(cabeceras[i].campo);
        const nombre = traductor(cabeceras[i].titulo);
        const texto = document.createTextNode(nombre);
        th.appendChild(texto);
        cabecera.appendChild(th);
    }
    tabla.appendChild(cabecera);

    for (let i in filas) {
        const fila = filas[i];
        let filaHTML = document.createElement("tr");
        for (let indice = 0; indice < camposVisibles.length; indice++) {
            let campo = camposVisibles[indice];
            if (Object.keys(fila).includes(campo)) {
                const td = document.createElement("td");
                const texto = document.createTextNode(fila[campo]);
                td.appendChild(texto);
                filaHTML.appendChild(td);
            } else {
                const td = document.createElement("td");
                const texto = document.createTextNode("");
                td.appendChild(texto);
                filaHTML.appendChild(td);
            }
        }
        tabla.appendChild(filaHTML);
    }

    return tabla;
};

const exportarACsv = (cabeceras, filas, traductor, tabla = null) => {
    if (!tabla) {
        tabla = crearTabla(cabeceras, filas, traductor);
    }

    let csv = [];
    let rows = tabla.querySelectorAll("tr");

    for (let i = 0; i < rows.length; i++) {
        let row = [];
        let cols = rows[i].querySelectorAll("th, td");

        for (let j = 0; j < cols.length; j++) {
            let columna = cols[j];

            if (columna.innerText === "null") {
                row.push('""');
            } else {
                row.push('"' + columna.innerText + '"');
            }
        }

        row.push("\n");
        csv.push(row.join(";"));
    }

    let enlace = document.createElement("a");
    //escapamos el texto para añadir las tildes.
    let procesada = escape(csv);

    //Eliminamos las comas que nos genera al principio de línea
    procesada = procesada.replace(/%2C/g, "");

    enlace.setAttribute("href", "data:text/csv;charset=utf-8," + procesada);
    enlace.download = "export.csv";
    enlace.style.display = "none";
    document.body.appendChild(enlace);
    enlace.click();
    document.body.removeChild(enlace);

    return false;
};

const exportarAExcel = (cabeceras, filas, traductor, tabla = null, tablas = null) => {
    if (!tabla && !tablas) {
        tabla = crearTabla(cabeceras, filas, traductor);
    }

    var htmls = "";
    var uri = "data:application/vnd.ms-excel;base64,";
    var template =
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"></head><body>{table}</body></html>';

        var base64 = function(s) {
        return window.btoa(unescape(encodeURIComponent(s)));
    };

    var format = function(s, c) {
        return s.replace(/{(\w+)}/g, function(m, p) {
            return c[p];
        });
    };


    if (tabla) {
        htmls += tabla.outerHTML;
        htmls += "<br /><br />";
    }

    if (tablas) {
        tablas.forEach(filaTabla => {
            htmls += filaTabla.outerHTML;
            htmls += "<br /><br />";
        });
    }

    htmls = htmls.replaceAll("∞", "Inf");

    var ctx = {
        worksheet: "Worksheet",
        table: htmls
    };

    var link = document.createElement("a");
    link.download = "export.xls";
    link.href = uri + base64(format(template, ctx));
    link.click();
}

const redondear = (numero, decimales) => {
    return Number(Math.round(numero + "e" + decimales) + "e-" + decimales);
};

function isCssSmoothSCrollSupported() {
    return "scrollBehavior" in document.documentElement.style;
}

const customScroll = (left, top, method, target = window) => {
    if (isCssSmoothSCrollSupported()) {
        target.scrollTo({
            left: left,
            top: top,
            behavior: method
        });
    } else {
        target.scrollTo(left, top);
    }
};

const smoothScroll = (left, top, target) => {
    customScroll(left, top, "smooth", target);
};

const limpiaCadena = cadena => {
    if (!cadena || typeof cadena !== "string") {
        return cadena;
    }

    let r = cadena.toLowerCase();
    r = r.replace(new RegExp(/[àáâãäå]/g), "a");
    r = r.replace(new RegExp(/[èéêë]/g), "e");
    r = r.replace(new RegExp(/[ìíîï]/g), "i");
    r = r.replace(new RegExp(/[òóôõö]/g), "o");
    r = r.replace(new RegExp(/[ùúûü]/g), "u");
    //r = r.replace(new RegExp(/ñ/g),"n");
    return r;
};

const getIDsFromDicts = list => {
    return list.map(row => {
        return row.id;
    });
};

const arraysEquivalentes = function(array1, array2) {
    return JSON.stringify(array1) === JSON.stringify(array2);
};

const getCookie = function(name) {
    var match = decodeURIComponent(document.cookie).match(new RegExp("(^| )" + name + "=([^;]+)"));
    if (match) return match[2];
};

const crearTema = (opcionesTema, nuevoColor, nuevaLuz) => {
    let colorTema;

    if (nuevoColor) {
        colorTema = nuevoColor;
        window.localStorage.setItem("themeColor", colorTema);
    } else {
        colorTema = window.localStorage.getItem("themeColor");
        if (colorTema) {
            let colorDisponible = opcionesTema.find(fila => fila.ruta === colorTema);
            if (!colorDisponible) {
                colorTema = null;
            }
        }

        if (!colorTema) {
            colorTema = opcionesTema[0].ruta;
            window.localStorage.setItem("themeColor", colorTema);
        }
    }

    let luzTema;
    if (nuevaLuz) {
        luzTema = nuevaLuz;
        window.localStorage.setItem("theme", luzTema);
    } else {
        luzTema = window.localStorage.getItem("theme");
        if (!luzTema) {
            luzTema = "os";
            window.localStorage.setItem("theme", luzTema);
        }
    }

    if (luzTema === "os") {
        luzTema = window.matchMedia('(prefers-color-scheme: dark)').matches ? "dark" : "light";
    }
    
    if (colorTema === "#e70f47") {
        colorTema = "#f45e2c";
    }
    
    // if (esDesarrollo) {
    //     colorTema += "cc";
    // }

    let nuevoTema = createTheme({
        palette: {
            type: luzTema,
            primary: {
                main: colorTema,
                contrastText: "rgb(255, 255, 255)"
            },
            secondary: {
                main: colorTema
            }

            /*error: {
                main: '#f44336',
            }*/
        },
        typography: {
            useNextVariants: true
        }
    });

    return nuevoTema;
};

const establecerIdioma = opcionesIdiomas => {
    let idioma = window.localStorage.getItem("i18nextLng");
    if (idioma) {
        for (const filaIdioma in opcionesIdiomas) {
            if (opcionesIdiomas[filaIdioma].ruta === idioma) {
                return;
            }
        }
    }

    window.localStorage.setItem("i18nextLng", "en-EN");
};

const fechaEuropa = valor => {
    if (valor !== null ){
        let fechaCompleta = valor.match(/\d+/g),
        year = fechaCompleta[0],
        month = fechaCompleta[1], day = fechaCompleta[2];

        return day+'-'+month+'-'+year;
    } else {
        return ''
    }
}

export {
    esDesarrollo,
    clonar,
    estaVacio,
    fechaUTC,
    comprobarAdmiteInputMonth,
    fechaSinDia,
    fechaSinDiaUTC,
    fechaUTCString,
    recuperaSubCadena,
    recogerIdioma,
    exportarAExcel,
    exportarACsv,
    redondear,
    customScroll,
    smoothScroll,
    limpiaCadena,
    getIDsFromDicts,
    arraysEquivalentes,
    getCookie,
    crearTema,
    establecerIdioma,
    formatearNumero,
    fechaEuropa
};
