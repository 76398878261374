const permisos = {
    esAdmin: "ADMINISTRADORES",
    esComercial: "COMERCIALES",

    admin: {
        logentry: {
            add: "admin.add_logentry",
            change: "admin.change_logentry",
            delete: "admin.delete_logentry",
            view: "admin.view_logentry"
        }
    },

    auth: {
        user: {
            add: "auth.add_user",
            change: "auth.change_user",
            delete: "auth.delete_user",
            view: "auth.view_user",
            titulo: "users",
            ruta: "/users"
        },
        group: {
            add: "auth.add_group",
            change: "auth.change_group",
            delete: "auth.delete_group",
            view: "auth.view_group"
        },
        permission: {
            add: "auth.add_permission",
            change: "auth.change_permission",
            delete: "auth.delete_permission",
            view: "auth.view_permission"
        }
    },

    general: {
        titulo: "general"
    },

    gestion: {
        titulo: "management",
        puntosInterrupcion: {
            add: "gestion.add_punto_interrupcion",
            change: "gestion.change_punto_interrupcion",
            delete: "gestion.delete_punto_interrupcion",
            view: "gestion.view_punto_interrupcion",
            titulo: "visit_data",
            ruta: "/interruption_points",
            tituloComercial: "interruption_point",
            rutaComercial: "/interruption_point"
        },
        fotosPuntoInterrupcion: {
            add: "gestion.add_foto_punto_interrupcion",
            change: "gestion.change_foto_punto_interrupcion",
            delete: "gestion.delete_foto_punto_interrupcion",
            view: "gestion.view_foto_punto_interrupcion"
        }
    },

    mantenimientos: {
        titulo: "maintenances",
        marcas: {
            add: "mantenimiento.add_marca",
            change: "mantenimiento.change_marca",
            delete: "mantenimiento.delete_marca",
            view: "mantenimiento.view_marca",
            titulo: "brands",
            ruta: "/brands"
        },
        submarcas: {
            add: "mantenimiento.add_submarca",
            change: "mantenimiento.change_submarca",
            delete: "mantenimiento.delete_submarca",
            view: "mantenimiento.view_submarca"
        },
        empresas: {
            add: "mantenimiento.add_empresa",
            change: "mantenimiento.change_empresa",
            delete: "mantenimiento.delete_empresa",
            view: "mantenimiento.view_empresa",
            titulo: "companies",
            ruta: "/companies"
        },
        productos: {
            add: "mantenimiento.add_producto",
            change: "mantenimiento.change_producto",
            delete: "mantenimiento.delete_producto",
            view: "mantenimiento.view_producto",
            titulo: "products",
            ruta: "/products"
        },
        exhibicion: {
            add: "mantenimiento.add_exhibicion",
            change: "mantenimiento.change_exhibicion",
            delete: "mantenimiento.delete_exhibicion",
            view: "mantenimiento.view_exhibicion",
            titulo: "exhibition",
            ruta: "/exhibition"
        },
        seccion: {
            add: "mantenimiento.add_seccion",
            change: "mantenimiento.change_seccion",
            delete: "mantenimiento.delete_seccion",
            view: "mantenimiento.view_seccion",
            titulo: "section",
            ruta: "/section"
        },
        productosEmpresa: {
            add: "mantenimiento.add_producto_empresa",
            change: "mantenimiento.change_producto_empresa",
            delete: "mantenimiento.delete_producto_empresa",
            view: "mantenimiento.view_producto_empresa",
            titulo: "company_products",
            ruta: "/company_products"
        },
        referenciasProductoEmpresa: {
            add: "mantenimiento.add_producto_empresa_referencia",
            change: "mantenimiento.change_producto_empresa_referencia",
            delete: "mantenimiento.delete_producto_empresa_referencia",
            view: "mantenimiento.view_producto_empresa_referencia"
        },
        tiendas: {
            add: "mantenimiento.add_tienda",
            change: "mantenimiento.change_tienda",
            delete: "mantenimiento.delete_tienda",
            view: "mantenimiento.view_tienda",
            titulo: "shops",
            ruta: "/shops"
        },
        regiones: {
            add: "mantenimiento.add_region",
            change: "mantenimiento.change_region",
            delete: "mantenimiento.delete_region",
            view: "mantenimiento.view_region",
            titulo: "regions",
            ruta: "/regions"
        },
        zonasComerciales: {
            add: "mantenimiento.add_zona_comercial",
            change: "mantenimiento.change_zona_comercial",
            delete: "mantenimiento.delete_zona_comercial",
            view: "mantenimiento.view_zona_comercial",
            titulo: "shoping_areas",
            ruta: "/shoping_areas"
        },
        ejercicio: {
            add: "mantenimiento.add_definicion_ejercicio",
            change: "mantenimiento.change_definicion_ejercicio",
            delete: "mantenimiento.delete_definicion_ejercicio",
            view: "mantenimiento.view_definicion_ejercicio",
            titulo: "fiscal_year",
            ruta: "/fiscal_year"
        },
        planificador: {
            add: "gestion.add_planificador",
            change: "gestion.change_planificador",
            delete: "gestion.delete_planificador",
            view: "gestion.view_planificador",
            titulo: "planner",
            ruta: "/planner"
        }
    },

    oauth2: {
        accesstoken: {
            add: "oauth2_provider.add_accesstoken",
            change: "oauth2_provider.change_accesstoken",
            delete: "oauth2_provider.delete_accesstoken",
            view: "oauth2_provider.view_accesstoken"
        },
        application: {
            add: "oauth2_provider.add_application",
            change: "oauth2_provider.change_application",
            delete: "oauth2_provider.delete_application",
            view: "oauth2_provider.view_application"
        },
        grant: {
            add: "oauth2_provider.add_grant",
            change: "oauth2_provider.change_grant",
            delete: "oauth2_provider.delete_grant",
            view: "oauth2_provider.view_grant"
        },
        refreshtoken: {
            add: "oauth2_provider.add_refreshtoken",
            change: "oauth2_provider.change_refreshtoken",
            delete: "oauth2_provider.delete_refreshtoken",
            view: "oauth2_provider.view_refreshtoken"
        }
    },

    sessions: {
        session: {
            add: "sessions.add_session",
            change: "sessions.change_session",
            delete: "sessions.delete_session",
            view: "sessions.view_session"
        }
    }
};

export default permisos;
