import React from 'react';
import PropTypes from 'prop-types';
import { AppBar, Toolbar } from '@material-ui/core';

/**
 * Elemento barra horizontal.
 *
 * @version 0.1
 * @author [Mario Cantelar](https://www.sandav.es) | [mario.cantelar@sandav.es](mailto:mario.cantelar@sandav.es)
 */
const EleBarraAplicacion = ({ claseCss, posicion, denso, color, children }) => (
    <AppBar className={claseCss} position={posicion} color={color}>
        <Toolbar variant={denso ? 'dense' : 'regular'}>{children}</Toolbar>
    </AppBar>
);

EleBarraAplicacion.propTypes = {
    /**
     * Clase css que se le va a aplicar al al elemento barra aplicación.
     */
    claseCss: PropTypes.string,
    /**
     * Posición en la web de la barra ( fixed, absolute, sticky, static, relative )
     */
    posicion: PropTypes.oneOf(['fixed', 'absolute', 'sticky', 'static', 'relative']),
    /**
     * Booleano que indica si se aplica un padding menor o el básico.
     */
    denso: PropTypes.bool
};

EleBarraAplicacion.defaultProps = {
    posicion: 'static',
    denso: false
};

export default EleBarraAplicacion;
