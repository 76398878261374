import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { reactI18nextModule } from "react-i18next";
import packageJson from '../../package.json';

i18n.use(Backend)
    .use(LanguageDetector)
    .use(reactI18nextModule)
    .init({
        fallbackLng: "es",
        ns: ["translations"],
        load: "languageOnly",
        defaultNS: "translations",
        debug: false,
        interpolation: {
            escapeValue: false // not needed for react!!
        },

        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json?v=' + packageJson.version,
            // requestOptions: {
            //     cache: 'no-store',
            // },
        },

        react: {
            wait: global.screen.height > 0 ? true : false
        }
    });

export default i18n;
